
import BackgroundVideoPlayer from "@/components/BackgroundVideoPlayer"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import ConnectWithSSOProviderLink from "@/components/users/ConnectWithSSOProviderLink"
import { useNuxtApp } from "#app"
import { SsoProvider } from "@evercam/shared/types"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  components: {
    ConnectWithSSOProviderLink,
    EvercamLoadingAnimation,
    BackgroundVideoPlayer,
  },
  layout: "clean",
  middleware({ query, $keycloak, from }) {
    const accountStore = useAccountStore()
    const useKeycloak = query.keycloak == "true" || accountStore.isUsingKeycloak
    const idp = query.idp_hint || accountStore.idpHint

    if (from.fullPath.includes("widget")) {
      accountStore.redirectUrl = from.fullPath
    }
    if (useKeycloak && $keycloak) {
      $keycloak.redirectToSigninPage(idp)
    }
  },
  data: () => ({
    toggleValue: false,
    loading: false,
    fixAutofill: false,
    model: {
      username: "",
      password: "",
      redirectToZoho: false,
      disablePassword: false,
    },
    ssoProvider: SsoProvider,
  }),
  meta: {
    public: true,
  },
  head() {
    return {
      title: "Sign in",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    zohoLogin() {
      return this.$route.query.serviceurl && this.accountStore.token
    },
  },
  mounted() {
    if (this.$route.query.serviceurl && this.accountStore.token) {
      this.preformLogin()
    }
    this.detectAutofill()
  },
  methods: {
    checkEmail() {
      const email = this.model.username
      const emailDomain = email.substring(email.lastIndexOf("@"))
      // if email contains @sljv.ie or any SSO restricted company domain, let user know they should use SSO
      if (
        [
          "@sljv.ie",
          "@daa.ie",
          "@dublinairport.com",
          "@pjhegarty.ie",
          "@ecs.construction",
          "@hfa-ae.com",
          "@compassdatacenters.com",
        ].includes(emailDomain)
      ) {
        // Empty and Disable password field
        this.model.password = ""
        this.model.disablePassword = true

        return "You have to use the SSO button below to login."
      } else {
        this.model.disablePassword = false

        return true
      }
    },
    detectAutofill() {
      let intervalDetectAutofill = setInterval(() => {
        if (
          document.querySelectorAll('input[type="password"]:-webkit-autofill')
            .length > 0
        ) {
          this.fixAutofill = true
          clearInterval(intervalDetectAutofill)
        }
      }, 100)
      this.$setTimeout(() => {
        if (intervalDetectAutofill) {
          clearInterval(intervalDetectAutofill)
          intervalDetectAutofill = null
        }
      }, 3000)
    },
    async preformLogin() {
      if (useNuxtApp().vue2App.isOnline) {
        if (this.$route.query.serviceurl && this.accountStore.token) {
          this.accountStore.zohoLogin(this.accountStore)
        } else {
          this.model.redirectToZoho = this.$route.query.serviceurl
            ? true
            : false
          const form = this.model
          if (this.accountStore.token) {
            await this.accountStore.logout({ redirect: false })
          }
          await this.accountStore.login({ form })
        }
      } else {
        this.$notifications.warn(this.$i18n.t("content.no_connection_text"))
      }
    },
    toggleIcon() {
      this.toggleValue = !this.toggleValue
    },
  },
}
